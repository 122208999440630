import {
    ChangeEvent,
    KeyboardEvent,
    MouseEvent,
    useEffect,
    useState
} from "react";
import { Trans, useTranslation } from "react-i18next";

import { ClassesEnum } from "@/ts/enums/ClassesEnum";
import { getTrackingId } from "@/utils/utils";
import { router, useForm, usePage } from "@inertiajs/react";
import { useAtom, useSetAtom } from "jotai";

import {
    Box,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    Input,
    Link
} from "@chakra-ui/react";

import eukaFamily from "@assets/img/euka-family.svg";
import logoSvg from "@assets/img/logo-black-and-white.svg";

import { userLoginAtom } from "@atoms/parentsAtom";

import { Button } from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import PasswordInput from "@components/PasswordInput/PasswordInput";
import { Text } from "@components/Text";

const LoginContainer = () => {
    const { t } = useTranslation();
    const { errors } = usePage().props;
    const [hasErrors, setHasErrors] = useState(false);
    const setUserLogin = useSetAtom(userLoginAtom);
    const [userLogin] = useAtom(userLoginAtom);

    const { data, setData, post, processing } = useForm({
        username: "",
        password: "",
        remember_me: false
    });

    useEffect(() => {
        if (errors.message !== undefined && errors.message !== null) {
            setHasErrors(true);
        }
    }, [errors]);

    const onHandleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHasErrors(false);
        setData(
            event.target.name === "username" ? "username" : "password",
            event.target.value
        );
    };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        setHasErrors(false);
        if (event.key.toLowerCase() === "enter") {
            post(route("login"), {
                replace: true
            });
        }
    };

    const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setUserLogin({ rememberMe: data.remember_me, userName: data.username });

        post(route("login"), {
            replace: true
        });
    };

    useEffect(() => {
        if (userLogin.rememberMe) {
            setData({
                remember_me: userLogin.rememberMe,
                username: userLogin.userName,
                password: ""
            });
        }
    }, [userLogin]);

    useEffect(() => {
        const handlePopstate = () => {
            history.go(1);
        };

        history.pushState(null, "null", location.href);
        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, []);

    const renderAlert = () => (
        <Text textAlign={["left", "center"]} marginBottom="2rem">
            <Trans>Please enter your email and password to login.</Trans>
        </Text>
    );

    return (
        <Flex minHeight="100vh">
            <Flex
                flexDirection="column"
                flex={1}
                padding={8}
                backgroundColor="var(--brand-sunshine, #F1D567)"
                display={{ base: "none", md: "flex" }}
                backgroundImage={eukaFamily}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                backgroundSize="70%"
                className={ClassesEnum.LoginContainer}
            >
                <Image
                    src={logoSvg}
                    alt="Euka logo"
                    height="2.5rem"
                    width="5.75rem"
                />
            </Flex>
            <Flex
                flex={1}
                padding={9}
                direction="column"
                className={ClassesEnum.LoginContainer}
            >
                <Text textAlign="right">
                    {t("auth.loginPage.joinLink", "Don't have an account? ")}
                    <Link
                        href={route("external.ecom.home")}
                        isExternal
                        marginLeft={1}
                        data-tracking-id={getTrackingId(
                            [ClassesEnum.LoginContainer, ClassesEnum.Link],
                            "joinEuka"
                        )}
                    >
                        <Button
                            as="span"
                            fontSize={[14, 16]}
                            variant="link"
                            paddingInline={0}
                        >
                            {t("auth.loginPage.joinEuka", "Join Euka")}
                        </Button>
                    </Link>
                </Text>

                <Box
                    maxWidth="50rem"
                    width="100%"
                    margin="auto"
                    paddingBlock={8}
                >
                    <Image
                        src={logoSvg}
                        alt="Euka logo"
                        width="6rem"
                        margin="1rem auto 2rem"
                        display={{ base: "block", md: "none" }}
                    />

                    <Text
                        m="0 auto"
                        textAlign="center"
                        type="heading"
                        marginBottom="1rem"
                        data-tracking-id={getTrackingId(
                            [ClassesEnum.LoginContainer, ClassesEnum.Text],
                            "loginToYourParentPortal"
                        )}
                    >
                        {t(
                            "auth.loginPage.title",
                            "Log in to your Parent Portal"
                        )}
                    </Text>

                    {renderAlert()}

                    <Flex direction="column" as="form" gap={6}>
                        <FormControl
                            isInvalid={
                                hasErrors ||
                                (errors &&
                                    errors.username !== null &&
                                    errors.username !== undefined)
                            }
                            className={ClassesEnum.FormField}
                            data-tracking-id={getTrackingId(
                                [
                                    ClassesEnum.LoginContainer,
                                    ClassesEnum.FormField
                                ],
                                "emailAddressField"
                            )}
                        >
                            <FormLabel>
                                {t(
                                    "auth.loginPage.usernameLabel",
                                    "Email address"
                                )}
                            </FormLabel>
                            <Input
                                type="username"
                                name="username"
                                value={data.username}
                                data-testid="username"
                                onChange={onHandleChange}
                                placeholder="example@gmail.com"
                                className={ClassesEnum.Input}
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.FormField,
                                        ClassesEnum.Input
                                    ],
                                    "usernameInput"
                                )}
                            />
                            <FormErrorMessage>
                                {errors?.username}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            fontSize={15}
                            isInvalid={
                                hasErrors ||
                                (errors &&
                                    errors.password !== null &&
                                    errors.password !== undefined)
                            }
                            className={ClassesEnum.FormField}
                            data-tracking-id={getTrackingId(
                                [
                                    ClassesEnum.LoginContainer,
                                    ClassesEnum.FormField
                                ],
                                "passwordField"
                            )}
                        >
                            <FormLabel>
                                {t("auth.loginPage.passwordLabel", "Password")}
                            </FormLabel>
                            <PasswordInput
                                name="password"
                                value={data.password}
                                onKeyDown={onKeyDown}
                                onChange={onHandleChange}
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.FormField,
                                        ClassesEnum.Input
                                    ],
                                    "passwordInput"
                                )}
                            />
                            <FormErrorMessage>
                                {errors?.password}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={
                                hasErrors ||
                                (errors &&
                                    errors.message !== null &&
                                    errors.message !== undefined)
                            }
                        >
                            {hasErrors && (
                                <ErrorMessage message={errors?.message} />
                            )}
                        </FormControl>
                    </Flex>
                    <Flex
                        justifyContent="space-between"
                        alignItems="center"
                        gap={4}
                        flexWrap="wrap"
                        marginTop={2}
                    >
                        <Button
                            onClick={() => router.get(route("password.forgot"))}
                            fontSize={[14, 16]}
                            variant="link"
                            paddingInline={0}
                            fontWeight="normal"
                            data-tracking-id={getTrackingId(
                                [
                                    ClassesEnum.LoginContainer,
                                    ClassesEnum.Button
                                ],
                                "forgotPassword"
                            )}
                        >
                            {t(
                                "auth.loginPage.forgotPasswordLabel",
                                "Forgot password?"
                            )}
                        </Button>

                        <Flex gap="2rem" flexWrap="wrap" alignItems="center">
                            <Checkbox
                                isChecked={data.remember_me}
                                onChange={() =>
                                    setData("remember_me", !data.remember_me)
                                }
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.Checkbox
                                    ],
                                    "rememberMe"
                                )}
                            >
                                Remember me
                            </Checkbox>
                            <Button
                                onClick={handleSubmit}
                                colorScheme="ebonyClay"
                                isLoading={processing}
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.Button
                                    ],
                                    "logIn"
                                )}
                            >
                                {t("auth.loginPage.logInBtn", "Log in")}
                            </Button>
                        </Flex>
                    </Flex>

                    <Text marginTop="2.19rem">
                        <Trans>
                            <Link
                                href={route("external.ecom.terms-conditions")}
                                textDecoration="underline"
                                _hover={{ textDecoration: "underline" }}
                                isExternal
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.Link
                                    ],
                                    "termsOfService"
                                )}
                            >
                                Terms of service
                            </Link>
                            {t("auth.loginPage.terms", " and ")}
                            <Link
                                href={route("external.ecom.privacy-policy")}
                                textDecoration="underline"
                                _hover={{ textDecoration: "underline" }}
                                isExternal
                                data-tracking-id={getTrackingId(
                                    [
                                        ClassesEnum.LoginContainer,
                                        ClassesEnum.Link
                                    ],
                                    "privacyPolicy"
                                )}
                            >
                                Privacy Policy
                            </Link>
                        </Trans>
                    </Text>
                </Box>
            </Flex>
        </Flex>
    );
};

export default LoginContainer;
